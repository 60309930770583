
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("admin-panel/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("admin-panel/adapters/application", function(){ return i("../adapters/application.ts");});
d("admin-panel/adapters/charlotte/translation", function(){ return i("../adapters/charlotte/translation.ts");});
d("admin-panel/adapters/deep-dive", function(){ return i("../adapters/deep-dive.ts");});
d("admin-panel/adapters/sentinel", function(){ return i("../adapters/sentinel.ts");});
d("admin-panel/adapters/sentinel/role", function(){ return i("../adapters/sentinel/role.ts");});
d("admin-panel/adapters/sentinel/user", function(){ return i("../adapters/sentinel/user.ts");});
d("admin-panel/app", function(){ return i("../app.ts");});
d("admin-panel/authenticators/sentinel", function(){ return i("../authenticators/sentinel.ts");});
d("admin-panel/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("admin-panel/config/environment", function(){ return i("../config/environment.js");});
d("admin-panel/constants/ec-timeouts", function(){ return i("../constants/ec-timeouts.ts");});
d("admin-panel/constants/index", function(){ return i("../constants/index.ts");});
d("admin-panel/constants/ted-translations", function(){ return i("../constants/ted-translations.ts");});
d("admin-panel/constants/timezones", function(){ return i("../constants/timezones.ts");});
d("admin-panel/constants/types", function(){ return i("../constants/types.ts");});
d("admin-panel/data-adapter", function(){ return i("../data-adapter.js");});
d("admin-panel/flash/object", function(){ return i("../flash/object.js");});
d("admin-panel/formats", function(){ return i("../formats.js");});
d("admin-panel/initializers/active-model-adapter", function(){ return i("../initializers/active-model-adapter.js");});
d("admin-panel/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("admin-panel/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("admin-panel/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("admin-panel/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("admin-panel/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("admin-panel/initializers/export-application-global", function(){ return i("../initializers/export-application-global.js");});
d("admin-panel/initializers/install-function-helper-manager", function(){ return i("../initializers/install-function-helper-manager.js");});
d("admin-panel/initializers/usable-function-manager", function(){ return i("../initializers/usable-function-manager.js");});
d("admin-panel/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("admin-panel/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("admin-panel/instance-initializers/sentry-performance", function(){ return i("../instance-initializers/sentry-performance.js");});
d("admin-panel/models/category", function(){ return i("../models/category.ts");});
d("admin-panel/models/charlotte/translation", function(){ return i("../models/charlotte/translation.ts");});
d("admin-panel/models/content-group", function(){ return i("../models/content-group.ts");});
d("admin-panel/models/currency", function(){ return i("../models/currency.ts");});
d("admin-panel/models/deep-dive/green-option/order", function(){ return i("../models/deep-dive/green-option/order.ts");});
d("admin-panel/models/deep-dive/green-option/room", function(){ return i("../models/deep-dive/green-option/room.ts");});
d("admin-panel/models/elastic/filter-definition", function(){ return i("../models/elastic/filter-definition.ts");});
d("admin-panel/models/elastic/home-screen-tile", function(){ return i("../models/elastic/home-screen-tile.ts");});
d("admin-panel/models/elastic/home-screen", function(){ return i("../models/elastic/home-screen.ts");});
d("admin-panel/models/elastic/tile", function(){ return i("../models/elastic/tile.ts");});
d("admin-panel/models/hotel-locale", function(){ return i("../models/hotel-locale.ts");});
d("admin-panel/models/hotel", function(){ return i("../models/hotel.ts");});
d("admin-panel/models/image", function(){ return i("../models/image.ts");});
d("admin-panel/models/integrations/configuration", function(){ return i("../models/integrations/configuration.ts");});
d("admin-panel/models/integrations/remote-reference", function(){ return i("../models/integrations/remote-reference.ts");});
d("admin-panel/models/item", function(){ return i("../models/item.ts");});
d("admin-panel/models/locale", function(){ return i("../models/locale.ts");});
d("admin-panel/models/order", function(){ return i("../models/order.ts");});
d("admin-panel/models/page-item", function(){ return i("../models/page-item.ts");});
d("admin-panel/models/page", function(){ return i("../models/page.ts");});
d("admin-panel/models/price", function(){ return i("../models/price.ts");});
d("admin-panel/models/push-plan", function(){ return i("../models/push-plan.ts");});
d("admin-panel/models/push", function(){ return i("../models/push.ts");});
d("admin-panel/models/room-control/configuration", function(){ return i("../models/room-control/configuration.ts");});
d("admin-panel/models/room", function(){ return i("../models/room.ts");});
d("admin-panel/models/sentinel/role", function(){ return i("../models/sentinel/role.ts");});
d("admin-panel/models/sentinel/user", function(){ return i("../models/sentinel/user.ts");});
d("admin-panel/models/stay-manager/step", function(){ return i("../models/stay-manager/step.ts");});
d("admin-panel/resources/form-state", function(){ return i("../resources/form-state.ts");});
d("admin-panel/resources/utils", function(){ return i("../resources/utils.ts");});
d("admin-panel/router", function(){ return i("../router.js");});
d("admin-panel/serializers/-default", function(){ return i("../serializers/-default.js");});
d("admin-panel/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("admin-panel/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("admin-panel/serializers/application", function(){ return i("../serializers/application.ts");});
d("admin-panel/serializers/charlotte/translation", function(){ return i("../serializers/charlotte/translation.ts");});
d("admin-panel/serializers/deep-dive/green-option/order", function(){ return i("../serializers/deep-dive/green-option/order.ts");});
d("admin-panel/serializers/deep-dive/green-option/room", function(){ return i("../serializers/deep-dive/green-option/room.ts");});
d("admin-panel/serializers/item", function(){ return i("../serializers/item.ts");});
d("admin-panel/serializers/push", function(){ return i("../serializers/push.ts");});
d("admin-panel/serializers/sentinel", function(){ return i("../serializers/sentinel.ts");});
d("admin-panel/serializers/sentinel/role", function(){ return i("../serializers/sentinel/role.ts");});
d("admin-panel/serializers/sentinel/user", function(){ return i("../serializers/sentinel/user.ts");});
d("admin-panel/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("admin-panel/services/cookies", function(){ return i("../services/cookies.js");});
d("admin-panel/services/dialog-stack-provider", function(){ return i("../services/dialog-stack-provider.js");});
d("admin-panel/services/ember-sortable-internal-state", function(){ return i("../services/ember-sortable-internal-state.js");});
d("admin-panel/services/flash-messages", function(){ return i("../services/flash-messages.js");});
d("admin-panel/services/intl", function(){ return i("../services/intl.js");});
d("admin-panel/services/keyboard", function(){ return i("../services/keyboard.js");});
d("admin-panel/services/modal-manager", function(){ return i("../services/modal-manager.ts");});
d("admin-panel/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("admin-panel/services/page-title", function(){ return i("../services/page-title.js");});
d("admin-panel/services/session-info", function(){ return i("../services/session-info.ts");});
d("admin-panel/services/session", function(){ return i("../services/session.js");});
d("admin-panel/services/store", function(){ return i("../services/store.js");});
d("admin-panel/services/store", function(){ return i("../services/store.ts");});
d("admin-panel/session-stores/application", function(){ return i("../session-stores/application.js");});
d("admin-panel/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("admin-panel/transforms/date", function(){ return i("../transforms/date.js");});
d("admin-panel/transforms/nested-object", function(){ return i("../transforms/nested-object.ts");});
d("admin-panel/transforms/number", function(){ return i("../transforms/number.js");});
d("admin-panel/transforms/string", function(){ return i("../transforms/string.js");});
d("admin-panel/utils/iframe-resizer", function(){ return i("../utils/iframe-resizer.ts");});
d("admin-panel/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.js");});
d("admin-panel/utils/is-in-iframe", function(){ return i("../utils/is-in-iframe.ts");});
d("admin-panel/utils/items", function(){ return i("../utils/items.ts");});
d("admin-panel/utils/model", function(){ return i("../utils/model.ts");});
d("admin-panel/utils/object", function(){ return i("../utils/object.ts");});
d("admin-panel/utils/regex", function(){ return i("../utils/regex.ts");});
d("admin-panel/utils/sentry", function(){ return i("../utils/sentry.ts");});
d("admin-panel/utils/tables", function(){ return i("../utils/tables.ts");});
d("admin-panel/utils/titleize", function(){ return i("../utils/titleize.js");});
d("admin-panel/utils/type-utils", function(){ return i("../utils/type-utils.ts");});



w._embroiderRouteBundles_ = [
  {
    names: ["application"],
    load: function() {
      return import("./_route_/application.js");
    }
  },
  {
    names: ["authenticated.hotel.currency-list.currency-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.currency-list.currency-create.js");
    }
  },
  {
    names: ["authenticated.hotel.currency-list.currency-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.currency-list.currency-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.currency-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.currency-list.js");
    }
  },
  {
    names: ["authenticated.hotel.guest-journey-push-list.push-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.guest-journey-push-list.push-create.js");
    }
  },
  {
    names: ["authenticated.hotel.guest-journey-push-list.push-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.guest-journey-push-list.push-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.guest-journey-push-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.guest-journey-push-list.js");
    }
  },
  {
    names: ["authenticated.hotel.home-screen-list.byod-home-screen-tile-list.tile-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.home-screen-list.byod-home-screen-tile-list.tile-create.js");
    }
  },
  {
    names: ["authenticated.hotel.home-screen-list.byod-home-screen-tile-list.tile-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.home-screen-list.byod-home-screen-tile-list.tile-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.home-screen-list.byod-home-screen-tile-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.home-screen-list.byod-home-screen-tile-list.js");
    }
  },
  {
    names: ["authenticated.hotel.hotel-list.hotel-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.hotel-list.hotel-create.js");
    }
  },
  {
    names: ["authenticated.hotel.hotel-list.hotel-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.hotel-list.hotel-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.hotel-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.hotel-list.js");
    }
  },
  {
    names: ["authenticated.hotel.item-list.item-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.item-list.item-create.js");
    }
  },
  {
    names: ["authenticated.hotel.item-list.item-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.item-list.item-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.item-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.item-list.js");
    }
  },
  {
    names: ["authenticated.hotel.locale-list.locale-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.locale-list.locale-create.js");
    }
  },
  {
    names: ["authenticated.hotel.locale-list.locale-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.locale-list.locale-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.locale-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.locale-list.js");
    }
  },
  {
    names: ["authenticated.hotel.order-list.green-option.dashboard"],
    load: function() {
      return import("./_route_/authenticated.hotel.order-list.green-option.dashboard.js");
    }
  },
  {
    names: ["authenticated.hotel.order-list.green-option.order-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.order-list.green-option.order-list.js");
    }
  },
  {
    names: ["authenticated.hotel.room-list.room-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.room-list.room-create.js");
    }
  },
  {
    names: ["authenticated.hotel.room-list.room-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.room-list.room-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.room-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.room-list.js");
    }
  },
  {
    names: ["authenticated.hotel.stay-manager-step-list.index"],
    load: function() {
      return import("./_route_/authenticated.hotel.stay-manager-step-list.index.js");
    }
  },
  {
    names: ["authenticated.hotel.stay-manager-step-list.step-create"],
    load: function() {
      return import("./_route_/authenticated.hotel.stay-manager-step-list.step-create.js");
    }
  },
  {
    names: ["authenticated.hotel.stay-manager-step-list.step-edit"],
    load: function() {
      return import("./_route_/authenticated.hotel.stay-manager-step-list.step-edit.js");
    }
  },
  {
    names: ["authenticated.hotel.stay-manager-step-list"],
    load: function() {
      return import("./_route_/authenticated.hotel.stay-manager-step-list.js");
    }
  },
  {
    names: ["authenticated.hotel.dashboard"],
    load: function() {
      return import("./_route_/authenticated.hotel.dashboard.js");
    }
  },
  {
    names: ["authenticated.hotel"],
    load: function() {
      return import("./_route_/authenticated.hotel.js");
    }
  },
  {
    names: ["authenticated"],
    load: function() {
      return import("./_route_/authenticated.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"admin-panel","version":"0.0.0+433f49ce"});
}

